import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faChevronLeft,
  faSignHanging,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  BtnBold,
  BtnItalic,
  BtnRedo,
  BtnUndo,
  BtnStyles,
  Editor,
  createButton,
  EditorProvider,
  Toolbar,
  Separator,
} from "react-simple-wysiwyg";
import Helpers from "../../libs/Helpers";
import { decodeToken } from "react-jwt";
import Firma from "../../componentes/Firma";
import InputGroupText from "react-bootstrap/esm/InputGroupText";

function NewDoc(props) {
  const [firmas, setFirmas] = useState([]);

  const decode = decodeToken(localStorage.getItem("session"));
  const [logo1, setLogo1] = useState();
  const [logo2, setLogo2] = useState();
  const token = localStorage.getItem("session");

  const [nombres, setNombres] = React.useState();
  const [apellidos, setApellidos] = React.useState();
  const [roll, setRoll] = React.useState();
  const [congregation, setCongregation] = React.useState();
  const [addrcongretation, setAddrCongregation] = React.useState();

  const [valuesFirma, setValuesFirma] = useState({});
  const [valuesNombres, setValuesNombres] = useState({});
  const [valuesCargos, setValuesCargos] = useState({});

  const handleValueChangeFirma = (key, value) => {
    setValuesFirma((prevValues) => ({ ...prevValues, [key]: value }));
  };

  const handleValueNombres = (key, value) => {
    setValuesNombres((prevValues) => ({ ...prevValues, [key]: value }));
  };

  const handleValueCargos = (key, value) => {
    setValuesCargos((prevValues) => ({ ...prevValues, [key]: value }));
  };

  const [html, setHtml] = useState("");
  const BtnAlignLeft = createButton(
    "Align center",
    <FontAwesomeIcon icon={faAlignLeft} className="fa-fw" />,
    "justifyLeft"
  );
  const BtnAlignCenter = createButton(
    "Align center",
    <FontAwesomeIcon icon={faAlignCenter} className="fa-fw" />,
    "justifyCenter"
  );
  const BtnAlignRight = createButton(
    "Align center",
    <FontAwesomeIcon icon={faAlignRight} className="fa-fw" />,
    "justifyRight"
  );
  const BtnAlignJustify = createButton(
    "Align center",
    <FontAwesomeIcon icon={faAlignJustify} className="fa-fw" />,
    "justifyFull"
  );

  useEffect(() => {
    Helpers.postAuth("/layouts/all", "POST", token, {}).then((data) => {
      if (data) {
        setLogo1(data[0].logoprimario);
        setLogo2(data[0].logosecundario);
      }
    });
  }, [token]);

  const getRoll = (id) => {
    return new Promise((response, reject) => {
      Helpers.postAuth("/roles/one", "POST", localStorage.getItem("session"), {
        id,
      })
        .then((data) => {
          response(data?.roll);
        })
        .catch((err) => console.log(err));
    });
  };

  const getSuc = (nombre_sucursal) => {
    Helpers.postAuth(
      "/branches/onename",
      "POST",
      localStorage.getItem("session"),
      { nombre_sucursal }
    ).then((data) => {
      setCongregation(data.nombre_sucursal);
      setAddrCongregation(data.direccion);
    });
  };

  React.useEffect(() => {
    Helpers.postAuth("/admins/one", "POST", localStorage.getItem("session"), {
      id: decode?.v1.id,
    }).then(async (data) => {
      setNombres(data?.names);
      setApellidos(data?.lastnames);
      setRoll(await getRoll(data?.roll));
      getSuc(data?.congregation);
    });
  }, [decode]);

  const removeItem = (index) => {
    setFirmas(firmas.filter((o, i) => index !== i));
  };
  
  const firmasAll = [];

  const saveDocument = (e) => {
    e.preventDefault();
    firmas.map((values,id)=>firmasAll.push([{ nombre: valuesNombres[id], cargo: valuesCargos[id], firma: valuesFirma[id].firma}]));
    console.log(firmasAll)
  }

  return (
    <div>
      <Card className="border-0 bg-transparent shadow-sm">
        <Card.Header>
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setDocView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Volver a Documentos
          </Button>
        </Card.Header>
        <Card.Body>
          <Card className="border-0 bg-transparent shadow-sm">
            <Card.Header className="bg-transparent border-0">
              <Row>
                <Col sm={6}>
                  <Row>
                    <Col
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        src={logo1}
                        style={{
                          width: 200,
                          height: 200,
                          border: 0,
                        }}
                      />
                    </Col>
                    <Col
                      style={{
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={logo2}
                        style={{
                          width: 200,
                          height: 100,
                          border: 0,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={6}
                  style={{
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <div>IGLESIA PENTECOSTAL UNIDA DEL NOMBRE DE JESUS</div>
                  <div>
                    {nombres} {apellidos}
                  </div>
                  <div>{roll}</div>
                  <div>{congregation}</div>
                  <div>{addrcongretation}</div>
                  <div>Chile</div>
                  <div>www.ipunje.cl</div>
                  <div>Ps Jurídica #02034</div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body style={{ height: 400 }}>
              <EditorProvider>
                <Editor
                  value={html}
                  onChange={(e) => setHtml(e.target.value)}
                  containerProps={{
                    style: {
                      height: "100%",
                      border: 0,
                      backgroundColor: "rgba(0,0,0,0)",
                    },
                  }}
                  placeholder="Ingrese el Contenido del Documento"
                  title="Documento Nuevo"
                >
                  <Toolbar
                    containerProps={{
                      style: { backgroundColor: "rgba(0,0,0,0)" },
                    }}
                  >
                    <BtnUndo />
                    <BtnRedo />
                    <Separator />
                    <BtnBold />
                    <BtnItalic />
                    <Separator />
                    <BtnAlignLeft />
                    <BtnAlignCenter />
                    <BtnAlignRight />
                    <BtnAlignJustify />
                    <BtnStyles />
                  </Toolbar>
                </Editor>
              </EditorProvider>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setFirmas(firmas.concat([{ id: 1, nombre: "", cargo: "" }]));
                }}
              >
                <FontAwesomeIcon icon={faSignHanging} className="fa-fw me-2" />
                Agregar Firma
              </Button>
              <Row>
                {firmas.map((signed, id) => (
                  <Col key={id} sm={6} className="mb-2 mt-2">
                    <Card>
                      <Card.Header>
                        <InputGroup>
                          <InputGroupText>Nombre</InputGroupText>
                          <Form.Control
                            type="text"
                            style={{
                              borderBottomRightRadius: 5,
                              borderTopRightRadius: 5,
                            }}
                            onChange={(values)=>handleValueNombres(id, values.target.value)}
                          />
                          <InputGroupText className="p-0 ms-1 rounded border-0">
                            <Button sm variant="danger">
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="fa-fw"
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeItem(id);
                                }}
                              />
                            </Button>
                          </InputGroupText>
                        </InputGroup>
                      </Card.Header>
                      <Card.Body>
                        <Firma
                          identificador={id}
                          valueChange={(value) =>
                            handleValueChangeFirma(id, value)
                          }
                        />
                      </Card.Body>
                      <Card.Footer>
                        <InputGroup>
                          <InputGroupText>Cargo</InputGroupText>
                          <Form.Control type="text" onChange={(values)=>handleValueCargos(id, values.target.value)} />
                        </InputGroup>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Button className="w-100" onClick={(e)=>saveDocument(e)}>Guardar Documento</Button>
            </Card.Footer>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
}

export default NewDoc;
