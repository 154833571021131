import React from "react";
import {
  ButtonGroup,
  Button,
  Card,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Helpers from "../../libs/Helpers";
import Loader from "../../libs/Loader"
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_ES } from "@ag-grid-community/locale";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

function BranchesList(props) {
  ModuleRegistry.registerModules([ClientSideRowModelModule]);
  const [loading, setLoading] = React.useState(true);
  const [sucursales, setSucursales] = React.useState([]);
  const gridRef = React.useRef();

  const localeText = AG_GRID_LOCALE_ES;

  const cambiarVista = (id) => {
    props.Sucursal.setSelectId(id);
    props.Vista.setBranchView(1);
  };

  const renderTooltipV = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Visualizar Información
    </Tooltip>
  );

  const CustomButtonComponent = (props) => {
    return (
      <>
        <ButtonGroup>
        <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipV}
          >
          <Button
            variant="light"
            className="btn btn-sm"
            onClick={(e) => {
              e.preventDefault();
              cambiarVista(props.data._id);
            }}
          >
            <FontAwesomeIcon icon={faEye} className="fa-fw" />
          </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Sucursal",
      field: "nombre_sucursal",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "Pastor",
      field: "pastor",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    { field: "Acciones", cellRenderer: CustomButtonComponent, flex: 1 },
  ];

  React.useEffect(() => {
    Helpers.postAuth(
      "/branches/all",
      "POST",
      localStorage.getItem("session"),
      {}
    ).then((data) => {
      setSucursales(data);
      setLoading(false);
    });
  }, []);

  const defaultColDef = {
    flex: 1,
  };

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 30];

  return (
    <div>
      {loading && (
        <Loader />
      )}
      <Card className="border-0 bg-transparent shadow-sm">
        <Card.Header>
          <Row>
            <Col>
              <span className="fw-bold d-flex justify-content-start align-items-center h-100">
                Gestión de Sucursales
              </span>
            </Col>
            <Col className="text-end">
              <Button
                variant="success"
                onClick={(e) => {
                  e.preventDefault();
                  props.Vista.setBranchView(2);
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-fw me-2" />
                Nueva Sucursal
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-1">
          <div className="ag-theme-quartz" style={{ height: 550 }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={sucursales}
              defaultColDef={defaultColDef}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              localeText={localeText}
            />
          </div>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </div>
  );
}

// eslint-disable-next-line
export default BranchesList;
