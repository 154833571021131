import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Collapse,
  Alert,
  Image,
  ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faChevronLeft,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Helpers from "../../libs/Helpers";

function MembersAdd(props) {
  const token = localStorage.getItem("session");

  const [group_family, setGroupFamily] = useState();
  const [names, setNames] = useState();
  const [lastnames, setLastnames] = useState();
  const [typedoc, setTypedoc] = useState();
  const [numdoc, setNumdoc] = useState();
  const [nationality, setNationality] = useState();
  const [placeofbirth, setPlaceofbirth] = useState();
  const [dateofbirth, setDateofbirth] = useState();
  const [gender, setGender] = useState();
  const [address, setAddress] = useState();
  const [phonenumber, setPhonenumber] = useState();
  const [civilstatus, setCivilstatus] = useState();
  const [placefamily, setPlacefamily] = useState();
  const [scholarship, setScholarship] = useState();
  const [congregation, setCongregation] = useState();
  const [occupation, setOccupation] = useState();
  const [datewaterbaptism, setDatewaterbaptism] = useState();
  const [citybaptism, setCitybaptism] = useState();
  const [shepherdbaptism, setPShepherdbaptism] = useState();
  const [holyspirit, setHolyspirit] = useState();
  const [positionsheld, setPositionsheld] = useState();
  const [howmeetlord, setHowmeetlord] = useState();
  const [addnote, setAddnote] = useState();
  const [photo, setPhoto] = useState();
  const [email, setEmail] = useState();

  const fileRef = useRef();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const [grupo_familiar, setGrupoFamiliar] = useState([]);
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    Helpers.postAuth("/family/all", "POST", token, {}).then((data) => {
      setGrupoFamiliar(data);
    });
    Helpers.postAuth("/branches/all", "POST", token, {}).then((data1) => {
      setSucursales(data1);
    });
  }, [token]);

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const validateInput = (msg) => {
    setVariantAlert("warning");
    showAlert(msg);
  };

  const addMember = () => {
    !names
      ? validateInput("Debes Ingresar los Nombres")
      : !lastnames
      ? validateInput("Debes Ingresar los Apellidos")
      : !typedoc
      ? validateInput("Debes Seleccionar el Tipo de Documento")
      : !numdoc
      ? validateInput("Debes Ingresar el Número del Documento")
      : !nationality
      ? validateInput("Debes Seleccionar el Pais de Origen")
      : !placeofbirth
      ? validateInput("Debes Ingresar el Lugar de Nacimiento")
      : !dateofbirth
      ? validateInput("Debes Seleccionar la Fecha de Nacimiento")
      : !gender
      ? validateInput("Debes Seleccionar el Género")
      : !address
      ? validateInput("Debes Incluir la Dirección de Residencia")
      : !phonenumber
      ? validateInput("Debes Incluir el Número de Te´lefono")
      : !civilstatus
      ? validateInput("Debes Seleccionar el Estado Civil")
      : !group_family
      ? validateInput("Debes Seleccionar un Grupo Familiar")
      : !placefamily
      ? validateInput("Debes Seleccionar el Lugar que Ocupa en la Familia")
      : !scholarship
      ? validateInput("Debes Seleccionar el Nivel de Estudios")
      : !congregation
      ? validateInput("Debes Seleccionar la Congregación a la que pertenece")
      : !occupation
      ? validateInput("Debes Seleccionar la Ocupación")
      : !photo
      ? validateInput("Debes Incluir el Registro Fotográfico")
      : !email
      ? validateInput("Debes Ingresar la Dirección de Correo")
      : Helpers.postAuth("/members/add", "POST", token, {
          group_family,
          names,
          lastnames,
          typedoc,
          numdoc,
          nationality,
          placeofbirth,
          dateofbirth,
          gender,
          address,
          phonenumber,
          civilstatus,
          placefamily,
          scholarship,
          congregation,
          occupation,
          datewaterbaptism,
          citybaptism,
          shepherdbaptism,
          holyspirit,
          positionsheld,
          howmeetlord,
          addnote,
          photo,
          email,
        }).then((data, err) => {
          if (err) throw err;
          setVariantAlert("info");
          showAlert("Registro Creado con Exito");
          setTimeout(() => {
            props.Vista.setMemberView(0);
          }, 3000);
        });
  };

  return (
    <div>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header>
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setMemberView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Agregar Nuevo Registro
          </Button>
        </Card.Header>
        <Card.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Nombres
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={names}
                    onChange={(e) => {
                      setNames(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup className="bg-transparent">
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Apellidos
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={lastnames}
                    onChange={(e) => {
                      setLastnames(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Tipo Documento
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={typedoc}
                    onChange={(e) => {
                      setTypedoc(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="pasaporte">Pasaporte</option>
                    <option value="rut">RUT</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Documento
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={numdoc}
                    onChange={(e) => {
                      setNumdoc(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Nacionalidad
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={nationality}
                    onChange={(e) => {
                      setNationality(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Brasil">Brasil</option>
                    <option value="Chile">Chile</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Guatemala">Guatemala</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Ciudad Nacimiento
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={placeofbirth}
                    onChange={(e) => {
                      setPlaceofbirth(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Fecha Nacimiento
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    required
                    value={dateofbirth}
                    onChange={(e) => {
                      setDateofbirth(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Género
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Femenino">Femenino</option>
                    <option value="Masculino">Masculino</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Dirección
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Teléfono
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={phonenumber}
                    onChange={(e) => {
                      setPhonenumber(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Correo
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Estado Civil
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={civilstatus}
                    onChange={(e) => {
                      setCivilstatus(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Soltero(a)">Soltero(a)</option>
                    <option value="Casado(a)">Casado(a)</option>
                    <option value="Viudo(a)">Viudo(a)</option>
                    <option value="Divorciado(a)">Divorciado(a)</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Grupo Familiar
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={group_family}
                    onChange={(e) => {
                      setGroupFamily(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    {grupo_familiar.map((data, id) => (
                      <option key={id} value={data.name_family}>
                        {data.name_family}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Lugar en la Familia
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={placefamily}
                    onChange={(e) => {
                      setPlacefamily(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Padre">Padre</option>
                    <option value="Madre">Madre</option>
                    <option value="Hijo(a)">Hijo(a)</option>
                    <option value="Sobrino(a)">Sobrino(a)</option>
                    <option value="Primo(a)">Primo(a)</option>
                    <option value="Hermano(a)">Hermano(a)</option>
                    <option value="Abuelo(a)">Abuelo(a)</option>
                    <option value="Otro">Otro</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Nivel Educación
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={scholarship}
                    onChange={(e) => {
                      setScholarship(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Pre-Escolar">Pre-Escolar</option>
                    <option value="Enseñanza Básica">Enseñanza Básica</option>
                    <option value="Enseñanza Media">Enseñanza Media</option>
                    <option value="Educación Superior">
                      Educación Superior
                    </option>
                    <option value="Post-Titulos">Post-Titulos</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Ocupación
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={occupation}
                    onChange={(e) => {
                      setOccupation(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Empleado">Empleado</option>
                    <option value="Independiente">Independiente</option>
                    <option value="Hogar">Hogar</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12}>
                <hr
                  style={{
                    border: "4px solid #666",
                  }}
                />
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Fecha Bautismo
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    required
                    value={datewaterbaptism}
                    onChange={(e) => {
                      setDatewaterbaptism(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Lugar Bautismo
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={citybaptism}
                    onChange={(e) => {
                      setCitybaptism(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Pastor Bautismo
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={shepherdbaptism}
                    onChange={(e) => {
                      setPShepherdbaptism(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Espíritu Santo
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    required
                    value={holyspirit}
                    onChange={(e) => {
                      setHolyspirit(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={12} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Congregación
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={congregation}
                    onChange={(e) => {
                      setCongregation(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    {sucursales.map((data, id) => (
                      <option key={id} value={data.nombre_sucursal}>
                        {data.nombre_sucursal}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <Card className="bg-transparent">
                  <Card.Header>Cargos Desempeñados</Card.Header>
                  <Card.Body className="p-1">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={positionsheld}
                      onChange={(e) => setPositionsheld(e.target.value)}
                    ></Form.Control>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <Card className="bg-transparent">
                  <Card.Header>Como Conoció al Señor</Card.Header>
                  <Card.Body className="p-1">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={howmeetlord}
                      onChange={(e) => setHowmeetlord(e.target.value)}
                    ></Form.Control>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={12} className="mt-2">
                <Card className="bg-transparent">
                  <Card.Header>Notas Eventuales</Card.Header>
                  <Card.Body className="p-1">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={addnote}
                      onChange={(e) => setAddnote(e.target.value)}
                    ></Form.Control>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} className="mt-2">
                <Card className="bg-transparent">
                  <Card.Header>Registro Fotográfico</Card.Header>
                  <Card.Body
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {photo && (
                      <Image
                        className="imageAdd"
                        height={"auto"}
                        src={photo}
                        style={{
                          margin: 20,
                        }}
                      />
                    )}
                    <input
                      type="file"
                      ref={fileRef}
                      className="invisible"
                      accept="image/png, image/jpeg"
                      value=""
                      onChange={(e) => {
                        e.preventDefault();
                        const reader = new FileReader();
                        let baseString;
                        reader.onloadend = function () {
                          baseString = reader.result;
                          setPhoto(baseString);
                        };
                        reader.readAsDataURL(e.target.files[0]);
                      }}
                    />
                    <Button
                      className="btn btn-lg shadow"
                      variant="light"
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    >
                      <FontAwesomeIcon icon={faCamera} className="fa-fw"  beat/>{" "}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="mt-4">
                <ButtonGroup>
                  <Button
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      addMember();
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} className="fa-fw me-2" />{" "}
                    Guardar Resgistro
                  </Button>
                  <Button onClick={(e)=>{
                    e.preventDefault();
                    props.Member.setSelectId(91075818)
                    props.Vista.setMemberView(3)
                  }}>
                      <FontAwesomeIcon icon={faFilePdf} className="fa-fw" />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </div>
  );
}

export default MembersAdd;
