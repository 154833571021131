import React from "react";
import MainLogin from "../screens/Login/MainLogin";
import { Container } from "react-bootstrap";
import { Button, Col, Row, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logoAdmin from "../assets/Images/Ipunsoft.png";
import { isExpired } from "react-jwt";
import Warning from "../libs/Warning";

function MainLayout({ children }) {
  const navigation = new useNavigate();

  React.useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  return (
    <div>
      <Warning>
        {!isExpired(localStorage.getItem("session")) ? (
          <div className="w-100 vh-100 garrocha">
            <Container fluid>
              <Row>
                <Col sm={12} md={2} className="barraLateral">
                  <div
                    style={{
                      width: "100%",
                      padding: 10,
                      marginBottom: 10,
                      position: "sticky",
                      top: 0,
                      zIndex: 10,
                    }}
                    className="imagenLogo"
                  >
                    <img
                      src={logoAdmin}
                      alt="logoadmin"
                      style={{
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigation("/");
                      }}
                    />
                  </div>
                  <div>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item
                        eventKey="10"
                        style={{
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      >
                        <Accordion.Header>Perfíl</Accordion.Header>
                        <Accordion.Body>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/profile");
                            }}
                          >
                            Ver Perfíl
                          </Button>
                          <br />
                          <Button
                            variant="light"
                            className="w-100 rounded-0"
                            onClick={(e) => {
                              e.preventDefault();
                              localStorage.removeItem("session");
                              navigation("/");
                            }}
                          >
                            Finalizar Sesión
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="11"
                        style={{
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      >
                        <Accordion.Header
                          style={{
                            backgroundColor: "rgba(0,0,0,0)",
                          }}
                        >
                          Congregacional
                        </Accordion.Header>
                        <Accordion.Body>
                          
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/congregational/members");
                            }}
                          >
                            Membresía
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/congregational/trasp");
                            }}
                          >
                            Traspaso
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/congregational/familys");
                            }}
                          >
                            Familias
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/congregational/friends");
                            }}
                          >
                            Amigos / Visitas
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/congregational/documental");
                            }}
                          >
                            Gestión Documental
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="12"
                        style={{
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      >
                        <Accordion.Header
                          style={{
                            backgroundColor: "rgba(0,0,0,0)",
                          }}
                        >
                          Inventario
                        </Accordion.Header>
                        <Accordion.Body>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/inventory/actives");
                            }}
                          >
                            Activos
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="13"
                        style={{
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      >
                        <Accordion.Header
                          style={{
                            backgroundColor: "rgba(0,0,0,0)",
                          }}
                        >
                          Financiero
                        </Accordion.Header>
                        <Accordion.Body>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/financial/accounting");
                            }}
                          >
                            Cuentas Contables
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/financial/tithes");
                            }}
                          >
                            Diesmoz y Ofrendas
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/financial/credits");
                            }}
                          >
                            Créditos
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/financial/opening");
                            }}
                          >
                            Cierre y Apertura
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="14"
                        style={{
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      >
                        <Accordion.Header
                          style={{
                            backgroundColor: "rgba(0,0,0,0)",
                          }}
                        >
                          Reportes
                        </Accordion.Header>
                        <Accordion.Body>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/reports/eclesiastics");
                            }}
                          >
                            Eclesíasticos
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/reports/financial");
                            }}
                          >
                            Financieros
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="15">
                        <Accordion.Header>Administrar</Accordion.Header>
                        <Accordion.Body>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/admin/users");
                            }}
                          >
                            Usuarios
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/admin/licences");
                            }}
                          >
                            Licencias
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/admin/oficials");
                            }}
                          >
                            Oficiales
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/admin/roles");
                            }}
                          >
                            Roles
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/congregational/branches");
                            }}
                          >
                            Sucursales
                          </Button>
                          <Button
                            variant="light"
                            className="w-100 rounded-0 mb-1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigation("/congregational/distritos");
                            }}
                          >
                            Distritos
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div
                    style={{
                      padding: 10,
                    }}
                  ></div>
                </Col>
                <Col sm={12} md={10} className="barraCentral">
                  {children}
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <MainLogin />
        )}
      </Warning>
    </div>
  );
}

export default MainLayout;
