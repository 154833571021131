import React, { useRef } from "react";
import {
  Font,
  Image,
  View,
  Page,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import MyriadPro from "../assets/Fonts/MyriadPro.otf";

Font.register({
  family: "MyriadPro",
  src: MyriadPro,
});

const PDFDownloader = (props) => {
  const myDocument = useRef();
  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      fontFamily: "MyriadPro",
      padding: 0,
      flexDirection: "column",
    },
    titleContainer: { flexDirection: "row", marginTop: 0 },
    imageinaFull: {
      width: "100%",
      height: "100%",
      zIndex: 0,
    },
  });

  const DocumentTitle = () => {
    return (
      <>
        <View style={styles.titleContainer}>
          <View>
            <Image src={props.back} style={styles.imaginaFull} />
          </View>
        </View>
      </>
    );
  };

  const Documento = () => {
    return (
      <Document
        ref={myDocument}
        file={props.file}
        title={props.file}
        author="IPUNSOFT"
        producer="IPUNSOFT"
        subject="Documento Oficial IPUNJE Chile"
        keywords="Certificado, Documentos, IPUNJE"
        creator="IPUNSOFT"
      >
        <Page
          objectFit="fill"
          size="A4"
          style={styles.page}
          orientation="landscape"
          pageNumber={1}
          renderMode={"canvas"}
        >
          <DocumentTitle />
        </Page>
      </Document>
    );
  };

  return <>{<Documento />}</>;
};

export default PDFDownloader;
