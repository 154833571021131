import React from "react";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import PDFDownloader from "../../libs/PDFDownloader";
import { Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Commet } from "react-loading-indicators";
import { saveAs } from "file-saver";
import QRCode from "qrcode";

function MembersViewPDF(props) {
  const [loading, setLoading] = React.useState(true);

  const [qrCode, setQrCode] = React.useState("");
  const baseQR = "https://ipunsoft.ipunje.cl/verifymember/91075818";

  React.useEffect(() => {
    const opts = {
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      version: 7,
      quality: 1,
      margin: 4,
      color: {
        dark: "#0188CCFF",
        light: "#FFFFFFFF",
      },
    };

    QRCode.toDataURL(baseQR, opts).then((base64QR) => {
      setQrCode(base64QR);
    });
  }, [baseQR]);

  const renderTooltipV = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Descargar en PDF
    </Tooltip>
  );

  React.useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  });

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []);

  const NewPDF = () => {
    return (
      <PDFDownloader
        id={'64600cf5e0baee920ca2d77d'} //props.Member.selectId}
        success={{ setLoading }}
        file={`IPUNSOFT_Membresia_${props.Member.selectId}`}
        qr={{ qrCode }}
      />
    );
  };

  const saveFile = () => {
    pdf(<NewPDF />)
      .toBlob()
      .then((blob) =>
        saveAs(blob, `IPUNSOFT_Membresia_${props.Member.selectId}`)
      );
  };

  return (
    <>
      {loading && (
        <div
          style={{
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
          </div>
        </div>
      )}
      <Card className="shadow-sm border-0 bg-transparent h-100">
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setMemberView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Visor de Membresias
          </Button>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipV}
          >
            <Button
              variant="dark"
              onClick={(e) => {
                e.preventDefault();
                saveFile();
              }}
            >
              <FontAwesomeIcon icon={faDownload} className="fa-fw" />
            </Button>
          </OverlayTrigger>
        </Card.Header>
        <Card.Body className="p-1">
          <PDFViewer
            width={"100%"}
            height={"100%"}
            className="rounded"
            showToolbar={false}
          >
            <NewPDF />
          </PDFViewer>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </>
  );
}

export default MembersViewPDF;
