import React, { useState, useRef } from "react";
import { ButtonGroup, Button, Card } from "react-bootstrap";
import Signature from "@uiw/react-signature";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

function Firma({ identificador, valueChange }) {
  const [penColor, setPenColor] = useState("blue");
  const [penSize, setPenSize] = useState(8);
  const $svg = useRef(null);

  const saveSignature = () => {
    const svgelm = $svg.current?.svg?.cloneNode(true);
    const clientWidth = $svg.current?.svg?.clientWidth;
    const clientHeight = $svg.current?.svg?.clientHeight;
    svgelm.removeAttribute("style");
    svgelm.setAttribute("width", `${clientWidth}px`);
    svgelm.setAttribute("height", `${clientHeight}px`);
    svgelm.setAttribute("viewbox", `${clientWidth} ${clientHeight}`);
    const data = new XMLSerializer().serializeToString(svgelm);
    valueChange({
      id: identificador,
      firma: `data:image/svg+xml;base64,${window.btoa(
        unescape(encodeURIComponent(data))
      )}`,
    });
  };

  const downLoadSignature = () => {
    const svgelm = $svg.current?.svg?.cloneNode(true);
    const clientWidth = $svg.current?.svg?.clientWidth;
    const clientHeight = $svg.current?.svg?.clientHeight;
    svgelm.removeAttribute("style");
    svgelm.setAttribute("width", `${clientWidth}px`);
    svgelm.setAttribute("height", `${clientHeight}px`);
    svgelm.setAttribute("viewbox", `${clientWidth} ${clientHeight}`);
    const data = new XMLSerializer().serializeToString(svgelm);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = clientWidth || 0;
      canvas.height = clientHeight || 0;
      ctx?.drawImage(img, 0, 0);
      const a = document.createElement('a');
      a.download = `signature_${identificador}.svg`;
      a.href = canvas.toDataURL('image/svg');
      a.click();
    };
    img.src = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(data)))}`;
  }

  return (
    <div>
      <Card>
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <div style={{ display: "flex" }}>
            <ButtonGroup>
              <Button
                sm
                variant="primary"
                onClick={() => setPenColor("blue")}
                className="microButton"
              ></Button>
              <Button
                sm
                variant="dark"
                onClick={() => setPenColor("black")}
                className="microButton"
              ></Button>
              <Button
                sm
                variant="success"
                onClick={() => setPenColor("green")}
                className="microButton"
              ></Button>
              <Button
                sm
                variant="danger"
                onClick={() => setPenColor("red")}
                className="microButton"
              ></Button>
            </ButtonGroup>

            <ButtonGroup className="ms-1">
              <Button
                sm
                variant="secondary"
                onClick={() => {
                  setPenSize(penSize - 1);
                }}
                className="microButton"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon icon={faMinus} className="fa-fw" />
                </div>
              </Button>
              <Button
                sm
                variant="secondary"
                onClick={() => {
                  setPenSize(penSize + 1);
                }}
                className="microButton"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon icon={faPlus} className="fa-fw" />
                </div>
              </Button>
            </ButtonGroup>

            <ButtonGroup className="ms-1">
              <Button
                sm
                variant="info"
                onClick={() => {
                  console.log($svg.current?.value);
                  $svg.current?.clear();
                }}
                className="microButton"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon icon={faTrash} className="fa-fw" />
                </div>
              </Button>
              <Button
                sm
                variant="info"
                onClick={() => {
                  downLoadSignature();
                }}
                className="microButton"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon icon={faSave} className="fa-fw" />
                </div>
              </Button>
            </ButtonGroup>
          </div>
        </Card.Header>
        <Card.Body>
          <Signature
            ref={$svg}
            fill={penColor}
            style={{ "--w-signature-background": "rgba(0,0,0,0)" }}
            onPointer={saveSignature}
            options={{
              size: penSize,
              smoothing: 0.46,
              thinning: 0.8,
              streamline: 0.5,
              easing: (t) => t,
              simulatePressure: true,
              last: true,
              start: {
                cap: true,
                taper: 0,
                easing: (t) => t,
              },
              end: {
                cap: true,
                taper: 0,
                easing: (t) => t,
              },
            }}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default Firma;
